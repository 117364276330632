<template>
 <div id="page-container" class="pb-5">
  <div class="content-wrap">
   <PbxDashboard
    v-model:selector="periodSelector"
    @setSelector="setSelector = $event"
    @action="(isQueryParam = false), getDataFromElementForColumn()"
    @select-hostContext="getDataFromElementForColumn()"
    @all-selected="allSelected($event)"
    @button-selector="(...args) => getSelectedButton(...args)"
    @timeSelector="(isQueryParam = false), setTimeSelected($event)"
    :buttonSelected="this.buttonSelected"
    :extensions="extensions"
    :dataColumnChartQuarterHourlyCons="dataColumnChartCons"
    :dataColumnChartQuarterHourly="dataColumnChart"
    :dataColumnChartHourly="dataColumnChart"
    :dataColumnChartHourlyCons="dataColumnChartCons"
    :periodSelector="this.periodSelector"
    :timeSelected="this.timeSelected"
    :textMonth="this.textMonth"
    :urlType="urlType"
    :queryElement="queryElement"
    @concatenate="concatenate = $event"
   />
  </div>
  <!-- <div v-show="extensions.length === 0"><EmptyStateDashboard /></div> -->
 </div>
 <Loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

export default {
 props: ["urlType"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
 },
 data() {
  return {
   concatenate: false,
   queryElement: [],
   setSelector: undefined,
   isQueryParam: false,
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataColumnChart: [],
   dataColumnChartCons: [],
   extensions: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: "month",
   textMonth: "",
   data: undefined,
  };
 },
 methods: {
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getPbxData(stringOfElements, sentPeriod, type) {
   let period = "";
   let start = "";
   let end = "";
   if (sentPeriod) {
    period = sentPeriod;
   } else {
    period = this.period;
   }
   if (period.length == 6) {
    start = this.parseDateDayMonthYearHifen(this.getFirstDayOfMonth(period.slice(0, 4), period.slice(-2)));
    end = this.parseDateDayMonthYearHifen(this.getLastDayOfMonth(period.slice(0, 4), period.slice(-2)));
   }
   if (period.length == 8) {
    start = this.parseDateDayMonthYearHifen(new Date(period.slice(0, 4), parseInt(period.slice(4, 6)) - 1, parseInt(period.slice(-2))));
    end = start;
   }
   if (period.includes("-")) {
    start = this.parseDateDayMonthYearHifen(new Date(period.split("-")[0].slice(0, 4), parseInt(period.split("-")[0].slice(4, 6)) - 1, parseInt(period.split("-")[0].slice(-2))));
    end = this.parseDateDayMonthYearHifen(new Date(period.split("-")[1].slice(0, 4), parseInt(period.split("-")[1].slice(4, 6)) - 1, parseInt(period.split("-")[1].slice(-2))));
   }
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/answered?start=${start}&end=${end}&dns=${stringOfElements}&dn_type=${this.urlType === "queue" ? "4" : "0"}`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getDataFromElementForColumn(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;

   this.dataColumnChart = [];
   this.dataColumnChartCons = [];

   let period = this.currentMonthAndYearForPbx;
   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   if (this.setSelector) {
    period = this.setSelector;
   }

   // if (!this.isQueryParam) {
   //   this.$router.replace({ tableElement: null, period: null });
   // }

   if (!this.setSelector && this.$route.query.period && this.isQueryParam) {
    period = this.$route.query.period;
    this.periodSelector = this.$route.query.period;
   }

   if (this.periodSelector) {
    period = this.periodSelector;
   }

   let pbxElements = undefined;

   if (!this.pbxElements && this.$route.query.tableElement && this.isQueryParam) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements, period, type);
     this.data = res;
    }

    let keys = [];
    let keysConc = [];

    if (this.timeSelected.length > 0) {
     if (this.timeSelected[0].slice(-2) === "00" && this.timeSelected[1].slice(-2) === "00") {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     let startTimeSelected = this.timeSelected[0];
     let endTimeSelected = this.timeSelected[1];
    }

    keys = Object.keys(res.daily);
    keysConc = Object.keys(res["daily*"]);

    let days = [];
    days = res.days;

    let formatedDays = [];

    days.forEach((day) => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    if (keys.length > 0) {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];

      let filledArrayDataAns = new Array(days.length).fill(0);
      let filledArrayDataUns = new Array(days.length).fill(0);
      let filledArrayDataAnsCons = new Array(days.length).fill(0);
      let filledArrayDataUnsCons = new Array(days.length).fill(0);
      let filledArrayDataAnsHourly = [];
      let filledArrayDataAnsHourlyCons = [];
      let filledArrayDataUnsHourly = [];
      let filledArrayDataUnsHourlyCons = [];

      keys.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0];
       elementName = this.urlType === "queue" ? res.map[arrayOfElements[arrIndex]]?.name : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] == arrayOfElements[arrIndex]) {
        obj["name"] = splitElement[2] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataAns.splice(index, 1, parseInt(res.daily[element]) <= parseInt(res["daily"][element.replace("answered", "calls")]) ? parseInt(res.daily[element]) : parseInt(res["daily"][element.replace("answered", "calls")]));
          }
         }
         dataObjAns["name"] = "Répondus";
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataUns.splice(
            index,
            1,
            parseInt(res["daily"][element]) -
             (parseInt(res["daily"][element.replace("calls", "answered")]) ? (parseInt(res["daily"][element.replace("calls", "answered")]) <= parseInt(res["daily"][element]) ? parseInt(res["daily"][element.replace("calls", "answered")]) : parseInt(res["daily"][element])) : 0)
           );
          }
         }
         dataObjUn["name"] = "Non répondus";
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysConc.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0];
       elementName = this.urlType === "queue" ? res.map[arrayOfElements[arrIndex]]?.name : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataAnsCons.splice(index, 1, parseInt(res["daily*"][element]) <= parseInt(res["daily*"][element.replace("answered", "calls")]) ? parseInt(res["daily*"][element]) : parseInt(res["daily*"][element.replace("answered", "calls")]));
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res["daily*"][element]) -
             (parseInt(res["daily*"][element.replace("calls", "answered")]) ? (parseInt(res["daily*"][element.replace("calls", "answered")]) <= parseInt(res["daily*"][element]) ? parseInt(res["daily*"][element.replace("calls", "answered")]) : parseInt(res["daily*"][element])) : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      obj["labels"] = days;
      objCons["labels"] = days;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChart.splice(arrIndex, 1, obj);
      this.dataColumnChartCons.splice(0, 1, objCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objHourly = {};
      objCons = {};
      let elementName = "";
      elementName = this.urlType === "queue" ? res.map[arrayOfElements[arrIndex]]?.name : res.map[arrayOfElements[arrIndex]];

      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      obj["labels"] = [];
      objCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChart.splice(arrIndex, 1, obj);
      this.dataColumnChartCons.splice(0, 1, objCons);
     }
    }
   }
   this.$emit("setLoading", false);
  },
  async getTimeLabelRange(arr) {
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let start = ("0" + String(sortedArray[0])).slic;
   let end = ("0" + String(sortedArray[arr.length - 1])).slic;
   try {
    const res = await axios.post(`${this.$cookie.getCookie("API")}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`);
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeStartAndEnd(start, end) {
   try {
    const res = await axios.post(`${this.$cookie.getCookie("API")}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`);
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourly(arr) {
   let hourlyRange = [];
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let end = "";
   let start = ("0" + String(sortedArray[0]) + "00").slice(0, 4);
   if (String(sortedArray[arr.length - 1]).length > 1) {
    end = (String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   } else {
    end = ("0" + String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   }
   try {
    const res = await axios.post(`${this.$cookie.getCookie("API")}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`);
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourlyStartAndEnd(start, end) {
   let hourlyRange = [];
   try {
    const res = await axios.post(`${this.$cookie.getCookie("API")}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`);
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
 },
 mounted() {
  if (!this.periodSelector) {
   this.periodSelector = this.currentMonthAndYearForPbx;
  }
  if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;

  if (this.$route.query.tableElement && this.$route.query.period) {
   this.isQueryParam = true;
  }

  if (this.pbxElementType !== this.urlType) this.$store.dispatch("pbxElements", null);

  this.getButtonSelectedFromQueryPeriod();
 },
 computed: {
  ...mapGetters(["account", "pbxElements", "pbxTimeSelected", "hostName", "hostContext", "hostTimeZone", "pbxElementType", "pbxPeriodSelected", "pbxMap"]),

  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options).format(month).slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
 watch: {
  periodSelector: function (val) {
   this.getDataFromElementForColumn();
  },
  timeSelected: function (val) {
   this.getDataFromElementForColumn(true);
  },
  urlType: function (val) {
   this.$store.dispatch("pbxElements", null);
   this.extensions = [];
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
